import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import Icon from '../Icon/Icon'
import Button from '../Button'
import Image from '../Image/Image'
import Typography from '../Typography/Typography'
import selectCauseCarousalStyles from './SelectCauseCarousal.styles'
import twStyles from './SelectCauseCarousal.tw-styles'

const SelectCauseCarousal = ({
  title,
  text,
  activeLabel,
  inActiveLabel,
  iconName,
  image,
  handleSelect,
  isActive,
  variant,
  backgroundImage,
  pageSource,
  logo,
  charityImpactListCollection,
}) => {
  let charityCredit = 0
  let charityPoints = 0

  const handleCauseSelect = () => {
    handleSelect(charityImpactListCollection?.items?.[0]?.charityId)
  }

  useEffect(() => {
    if (variant === 'home') {
      const allelems = document.querySelectorAll('.slick-slide')
      allelems.forEach((allelem) => {
        allelem.removeAttribute('tabindex')
        allelem.removeAttribute('aria-hidden')
      })
    }
  }, [])
  if (variant === 'home') {
    charityPoints = parseInt(charityImpactListCollection?.items?.[0]?.totalCharityCounts || 0, 10)
    if (charityPoints >= 0) {
      charityCredit = charityPoints || 0
    }
  }
  const style = twStyles[variant] || twStyles.default
  return (
    <div className={style.selectWrapper}>
      <div className={style?.wrapper}>
        {pageSource !== 'signup' && (
        <div className={style.selectHeaderWrapper}>
          <div className={style.selectHead}>
            <div className={style.selectSubWrapper}>
              <Typography markdown={title} />
            </div>
          </div>
          <div className={style.selectCauseIcon}>
            {iconName && <Icon name={iconName} />}
            {image && <Image className={variant === 'home' || variant === 'sustainability' ? style.image[image?.variant] : style.selectCauseImage} {...image} />}
          </div>
          <Typography className={style.selectCauseImageText} markdown={title} />
          <div className={style.selectCauseImageIconWrapper}>
            <div className={style.selectCauseImageIcon}>
              {pageSource === 'sustainability' && logo && (
              <Image
                {...backgroundImage}
                key={backgroundImage?.sys?.id}
              />
              )}
            </div>
            <div className={style.selectCauseImageIconLogo}>
              { pageSource === 'sustainability' && logo && (
              <Image
                {...logo}
              />
              ) }

            </div>
          </div>
        </div>
        )}
        {pageSource === 'signup' && (
        <div>
          <div className='relative'>
            <div>
              {backgroundImage && (
              <Image
                {...backgroundImage}
                key={backgroundImage?.sys?.id}
              />
              )}
            </div>
            <div className={style.selectCauseIcon}>
              {iconName && <Icon name={iconName} />}
              {image && <Image className={variant === 'home' ? style.image[image.styles] : style.selectCauseImage} {...image} />}

            </div>
          </div>
          <Typography className={style.selectCauseImageText} markdown={title} />
        </div>
        )}
      </div>
      {variant === 'home' && <p className={style.selectCreditNo}>{charityCredit?.toLocaleString('en-US')}</p>}
      <div className={style.selectCauseTextWrapper}>
        <div className={style.selectCauseInnerText}>
          <Typography markdown={variant === 'home' ? text.replace('{charityPoints}', charityCredit?.toLocaleString('en-US')) : text} />
        </div>
      </div>
      <div className={style.selectCauseButton}>
        <Button handler={() => handleCauseSelect()} className={isActive && 'active'}>
          {isActive ? (
            <>
              {activeLabel}
              <Icon name='check' />
            </>
          ) : inActiveLabel}
        </Button>
      </div>
      <style jsx global>
        {selectCauseCarousalStyles}
      </style>
    </div>
  )
}

SelectCauseCarousal.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  activeLabel: PropTypes.string,
  inActiveLabel: PropTypes.string,
  iconName: PropTypes.string,
  destinationId: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  logo: PropTypes.oneOfType([PropTypes.object]),
  backgroundImage: PropTypes.oneOfType([PropTypes.object]),
  pageSource: PropTypes.string,
  charityImpactListCollection: PropTypes.oneOfType([PropTypes.object]),
}
SelectCauseCarousal.defaultProps = {
  image: null,
  logo: null,
  title: null,
  iconName: null,
  backgroundImage: null,
  pageSource: null,
  inActiveLabel: null,
  activeLabel: null,
  charityImpactListCollection: null,
}

export default observer(SelectCauseCarousal)
