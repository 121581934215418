import {
  signUp, signIn,
} from '../../constants/page-source'

module.exports = {
  [signUp]: {
    SelectModal: {
      videoSteps: 'pageStep1 text-grey border-rounded font-montserratBold font-bold text-12 border-2 border-grey',
      modalExternalWrapper: 'modalExternalWrapper signinModalCauseSelect shadow-cardshadow1 fixed inset-0 h-screen bg-white  overflow-y-auto overflow-x-hidden w-full z-50 pb-150 md:pb-110 md:pb-0',
      modalWrapper: 'modalWrapper container bg-white mx-auto w-full pt-30 md:pt-65 lg:py-70',
      modalWrapperVideoGating: 'modalWrapper video bg-white mx-auto w-full pt-15 md:pt-25 lg:pt-40',
      modalClose: 'SelectClose flex justify-end px-15 py-15',
      modalTitle: 'modalTitle text-center font-montserratSemiBold font-semibold text-24 leading-widest w-10/12 md:w-9/12 xl:w-9/12 mx-auto lg:text-32 lg:leading-leading42 pb-15 xl:pb-10',
      modalDescription: 'causeModalText causeSelectLink text-base leading-lightmedium pt-0 pb-18px md:pt-0 md:pb-25 lg:pt-0 lg:pb-25 lg:text-lg lg:leading-widest text-center w-11/12 px-10 md:w-9/12 lg:w-9/12 xl:w-7/12 xl:px-30 mx-auto font-montserratlight font-light',
      modalSubDescription: 'causeModalText causeSelectLink text-base leading-lightmedium py-0 lg:text-lg lg:leading-widest text-center w-11/12 px-10 md:w-9/12 lg:w-9/12 xl:w-7/12 xl:px-30 mx-auto font-montserratlight font-light',
      modalFooter: 'modalFooter pb-60 xl:pb-110 flex justify-center w-9/12 pt-20 md:w-8/12 lg:w-6/12 text-center mx-auto font-montserratlight font-light text-sm xl:text-16 leading-leading20 xl:leading-light',
      modalCta: '123 font-montserratBold font-bold flex justify-center text-18 leding-wider lg:text-23 pt-17 md:pt-25 lg:pt-20 fixed inset-x-0 shadow-receiptScanBtnShadow datepickerBtn pb-20 bottom-0 bg-white',
      selectErrorMessageWrapper: 'selectErrorEmail text-sm font-montserratSemiBold font-semibold leading-leading20 text-center w-full bg-redRewards mb-42px md:mb-29 lg:mb-42px py-10 md:py-20 lg:text-base lg:leading-light text-white lg:py-22',
      selectErrorMessageInnerWrapper: 'w-9/12 mx-auto xl:w-7/12 lg:px-50 xl:px-30',

    },
  },
  [signIn]: {
    SelectModal: {
      modalExternalWrapper: 'modalExternalWrapper signinModalCauseSelect shadow-cardshadow fixed inset-0 h-screen bg-white  overflow-y-auto overflow-x-hidden w-full z-50',
      modalWrapper: 'modalWrapper  bg-white    mx-auto w-full pt-60 pb-30 md:pt-65 lg:pt-70 md:w-11/12 xl:w-full',
      modalClose: 'SelectClose flex justify-end px-15 py-15',
      modalTitle: 'modalTitle text-center font-montserratSemiBold font-semibold text-24 leading-leading29 w-9/12 mx-auto lg:text-38 lg:leading-noImgh',
      modalDescription: 'causeModalText causeSelectLink text-base leading-lightmedium pt-25 pb-18px md:pt-12 md:pb-25 lg:pt-25 lg:pb-25 lg:text-lg lg:leading-widest text-center w-11/12 px-10 md:w-9/12 lg:w-9/12 xl:w-7/12 xl:px-30 mx-auto font-montserratlight font-light',
      modalFooter: 'modalFooter pb-170 md:pb-60 flex justify-center w-9/12 pt-20 md:w-8/12 lg:w-6/12 text-center mx-auto font-montserratlight font-light text-sm xl:text-16 leading-leading20 xl:leading-light',
      modalCta: 'completeModal font-montserratBold font-bold flex justify-center text-18 leading-wider lg:text-23 pt-17 md:pt-25 lg:pt-20 fixed inset-x-0 shadow-receiptScanBtnShadow datepickerBtn pb-20 bottom-0 bg-white',
      selectErrorMessageWrapper: 'selectErrorEmail text-sm font-montserratSemiBold font-semibold leading-leading20 text-center w-full bg-redRewards mb-42px md:mb-29 lg:mb-42px py-10 md:py-20 lg:text-base lg:leading-light text-white lg:py-22',
      selectErrorMessageInnerWrapper: 'w-9/12 mx-auto xl:w-7/12 lg:px-50 xl:px-30',
    },
  },
  home: {
    selectWrapper: 'selectCauseHomeWrapper bg-white border-3 relative ml-4px mr-5px pb-25 mb-30px border-bgcolor',
    selectHeaderWrapper: 'selectCauseHead relative bgreceiptFeedback h-120',
    selectHead: '',
    selectSubWrapper: 'causeTitleFix w-9/12 mx-auto hidden',
    selectCauseIcon: 'w-full flex border-t-10 relative border-receiptFeedback justify-center bg-white pt-20 px-50',
    selectCauseImage: 'absolute bottom-0 py-10 px-50',
    selectCauseImageText: 'hidden w-full font-montserratBold font-bold text-16 leading-seller18 text-center text-always',
    selectCreditNo: 'w-full text-center font-montserratSemiBold font-semibold text-38 leading-leading42 text-primary',
    selectCauseTextWrapper: 'w-10/12 mx-auto flex flex-wrap justify-center items-center font-montserratlight font-light text-xs leading-points16 text-center',
    selectCauseInnerText: 'w-full relative pt-05 font-montserratlight font-light text-16 leading-leading20 text-center',
    selectCauseButton: 'selectCauseButton absolute flex flex-wrap items-center justify-center shadow-rewardsBadge  font-montserratBold font-bold text-xs  text-white',
    image: {
      default: '',
      csdwLogo: 'csdw logo w-174 pt-40',
      tideLogo: 'tide logo w-137 pt-30',
      alwaysLogo: 'always logo w-150 pt-25',
      feedingLogo: 'feeding logo w-116 pt-20',
      operationLogo: 'oparation logo w-116 pt-0',
      dawnLogo: 'dawn logo w-95 pt-0',
      prideLogo: 'dawn logo w-72 h-72 pt-0',
      safeguardLogo: 'safe logo w-90 pt-15',
      panteneLogo: 'pantene logo w-137 ',
      unitedLogo: 'united logo w-100 lg:w-137 pt-20',
      takeRaceLogo: 'race logo w-140 pt-50',
      hispanicLogo: 'hispanic logo w-140 pt-20',
      cleanHomeLogo: 'clean-home logo w-100 pt-05',
    },
  },
  sustainability: {
    selectWrapper: 'selectCauseHomeWrapper bg-white border-3 relative min-h-337 md:min-h-315 xl:min-h-345 ml-4px mr-5px pb-25 mb-30px border-IOHLightGrey',
    selectHeaderWrapper: 'selectCauseHead relative bgreceiptFeedback',
    selectHead: '',
    selectSubWrapper: 'causeTitleFix w-9/12 mx-auto hidden',
    selectCauseIcon: 'w-full flex h-120 relative border-receiptFeedback justify-center bg-white pt-20 px-50',
    selectCauseImage: 'absolute bottom-0 py-10 px-50 z-1 w-150',
    selectCauseImageText: 'hidden w-full font-montserratBold font-bold text-16 leading-seller18 text-center text-always',
    selectCreditNo: 'w-full text-center font-montserratSemiBold font-semibold text-38 leading-leading42 text-primary',
    selectCauseTextWrapper: 'w-full px-03 xl:w-11/12 mx-auto flex flex-wrap justify-center items-center font-montserratlight font-light text-xs leading-points16 text-center',
    selectCauseInnerText: 'w-full relative pt-05 font-montserratlight font-regular text-16 leading-light text-center',
    selectCauseButton: 'selectCauseButton absolute flex flex-wrap items-center justify-center shadow-rewardsBadge  font-montserratBold font-bold text-xs  text-white',
    selectCauseImageIconWrapper: 'w-full',
    selectCauseImageIcon: 'absolute top-0',
    selectCauseImageIconLogo: 'absolute top-5 left-15 w-25',
    image: {
      default: 'always logo absolute top-5 px-10 z-1 w-110',
      csdwLogo: 'csdw logo absolute top-5 lg:top-10 px-10 z-1 w-95',
      dawnLogo: 'dawn logo absolute top-5 lg:top-10 px-10 z-1 w-75',
      tideLogo: 'tide logo absolute top-15 py-05 z-1 w-80',
      alwaysLogo: 'always logo absolute top-5 lg:top-10 py-10 z-1 w-110',
      feedingLogo: 'feeding logo absolute top-10 px-10 z-1 w-84',

    },
  },
  olympics: {
    selectWrapper: 'selectCauseHomeWrapper bg-white border-3 relative min-h-337 md:min-h-315 xl:min-h-345 ml-4px mr-5px pb-25 mb-30px border-IOHLightGrey',
    selectHeaderWrapper: 'selectCauseHead relative bgreceiptFeedback',
    selectHead: '',
    selectSubWrapper: 'causeTitleFix w-9/12 mx-auto hidden',
    selectCauseIcon: 'w-full flex h-120 relative border-receiptFeedback justify-center bg-white pt-20 px-50',
    selectCauseImage: 'relative -top-20 inset-x-0 py-10 px-10 z-1 w-160',
    selectCauseImageText: 'hidden w-full font-montserratBold font-bold text-16 leading-seller18 text-center text-always',
    selectCreditNo: 'w-full text-center font-montserratSemiBold font-semibold text-38 leading-leading42 text-primary',
    selectCauseTextWrapper: 'w-full px-03 xl:w-11/12 mx-auto flex flex-wrap justify-center items-center font-montserratlight font-light text-xs leading-points16 text-center',
    selectCauseInnerText: 'w-full relative text-black pt-05 font-montserratlight font-normal text-16 leading-light text-center',
    selectCauseButton: 'selectCauseButton absolute flex flex-wrap items-center justify-center shadow-rewardsBadge  font-montserratBold font-bold text-xs  text-white',
    selectCauseImageIconWrapper: 'w-full flex justify-center',
    selectCauseImageIcon: 'absolute top-0',
    selectCauseImageIconLogo: 'flex justify-center pt-45 pb-15 w-205',
    image: {
      default: 'always logo absolute top-5 px-10 z-1 w-110',
      csdwLogo: 'csdw logo absolute top-5 lg:top-10 px-10 z-1 w-95',
      dawnLogo: 'dawn logo absolute top-5 lg:top-10 px-10 z-1 w-75',
      tideLogo: 'tide logo absolute top-15 py-05 z-1 w-80',
      alwaysLogo: 'always logo absolute top-5 lg:top-10 py-10 z-1 w-110',
      feedingLogo: 'feeding logo absolute top-10 px-10 z-1 w-84',
    },
  },
  SelectModal: {
    modalExternalWrapper: 'modalExternalWrapper fixed inset-0 h-screen bg-white md:bg-selectCauseTB overflow-y-auto overflow-x-hidden w-full z-50',
    modalWrapper: 'modalWrapper  bg-white shadow-cardshadow   mx-auto w-full pt-60   md:pt-65 lg:pt-120 md:w-11/12',
    modalClose: 'SelectClose flex justify-end px-15 py-15',
    modalTitle: 'modalTitle text-center font-montserratSemiBold font-semibold text-24 leading-leading29 w-9/12 mx-auto lg:text-38 lg:leading-noImgh',
    modalDescription: 'modalDescription causeSelectLink text-base leading-lightmedium pt-25 pb-18px md:pt-12 md:pb-25 lg:pt-25 lg:pb-25 lg:text-lg lg:leading-widest text-center w-11/12 px-10 md:w-9/12 lg:w-9/12 xl:w-7/12 xl:px-30 mx-auto font-montserratlight font-light',
    modalFooter: 'modalFooter pb-20 flex justify-center w-9/12 pt-70 lg:pt-70 md:w-8/12 lg:w-6/12 text-center mx-auto font-montserratlight font-light text-sm leading-leading20',
    modalCta: 'font-montserratBold font-bold flex justify-center text-18 lg:text-23 pt-17 md:pt-25 lg:pt-20 fixed inset-x-0 shadow-receiptScanBtnShadow datepickerBtn pb-20 bottom-0 bg-white',
    selectErrorMessageWrapper: 'selectErrorEmail text-sm font-montserratSemiBold font-semibold leading-leading20 text-center w-full bg-redRewards mb-42px md:mb-29 lg:mb-42px py-10 md:py-20 lg:text-base lg:leading-light text-white lg:py-22',
    selectErrorMessageInnerWrapper: 'w-9/12 mx-auto xl:w-7/12 lg:px-50 xl:px-30',
  },
  default: {
    selectWrapper: 'min-h-290 w-280 mx-auto xl:mr-0 ',
    selectHeaderWrapper: 'selectCauseHead relative bg-receiptFeedback',
    selectSubWrapper: 'causeTitleFix w-9/12 mx-auto',
    selectCauseImageText: 'font-montserratSemiBold font-semibold text-16 lg:text-14 w-9/12  pt-40 mx-auto text-center leading-light lg:leading-points16',
    selectHead: 'SelectHead text-white text-xs border-1 items-center flex justify-center tracking-widest border-bgcolor shadow-goodTracker font-montserratBold font-bold font-montserratlight font-light leading-points16 text-center py-15',
    selectCauseIcon: 'selectCauseIcon absolute  rounded-full',
    selectCauseTextWrapper: 'flex flex-wrap justify-center items-center font-montserratlight font-light text-14 leading-leading20 text-center',
    selectCauseInnerText: 'selectcauseText relative font-montserratlight font-light text-14 leading-leading20 lg:leading-leading20 text-center',
    selectCauseButton: 'selectCauseButton absolute flex flex-wrap items-center justify-center shadow-rewardsBadge  font-montserratBold font-bold text-xs  text-white',
  },
}
